<template>
  <div class="card text-left">
    <div class="card-body">
      <div class="media d-flex flex-column flex-md-row">
        <a :href="hamershof_url" target="_blank" class="align-self-center mb-4 align-self-sm-baseline mr-md-4">
          <img v-if="logo_url" :src="logo_url+'?resize=w[150]'" :alt="title" @load="loaded" @error="loaded">
        </a>
        <div class="media-body">
          <h5>{{ title }}</h5>
          <p v-if="description">{{ description }}</p>
          <address v-if="address">{{ address }}</address>

          <template v-for="cls in ['btn-block d-lg-none', 'd-none d-lg-inline-block']">
            <a class="btn btn-primary" :class="cls" :href="hamershof_url" target="_blank">
              <i class="fa fa-fw fa-link" aria-hidden="true"></i>
              Op hamershof.nl
            </a>
            <!--
            <a v-if="url" class="btn btn-info" :class="cls" :href="url" target="_blank">
              <i class="fa fa-fw fa-external-link" aria-hidden="true"></i>
              Eigen website
            </a>
            -->
            <a v-if="phone_e164" class="btn btn-primary" :class="cls" :href="`tel:${phone_e164}`">
              <i class="fa fa-fw fa-phone" aria-hidden="true"></i>
              {{ phone_formatted }}
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    address: String,
    logo_url: String,
    url: String,
    hamershof_url: String,
    phone_e164: String,
    phone_formatted: String,
  },
  methods: {
    loaded()
    {
      this.$emit('loaded');
    }
  }
}
</script>