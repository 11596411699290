<template>
  <div class="app" ref="app">
    <p class="text-right" v-if="entryscreen && entryscreen.hash">
      <button class="btn btn-primary text-right" @click="reset">
        {{ entryscreen.email }} uitloggen
        <i class="fa fa-sign-out" aria-hidden="true"></i>
      </button>
    </p>

    <alert v-model="errors"></alert>

    <play-screen v-if="current_code && shop_ids" :code="current_code" :shop_ids="shop_ids"
                 @won="mail"
                 @lost="mail"
                 @play="play"
                 @close="close"
                 :next_codes="next_codes"
                 ref="play"
    ></play-screen>

    <entry-screen v-else-if="!entryscreen || !entryscreen.hash" v-model="entryscreen" ref="entryscreen"></entry-screen>

    <codes-screen v-else-if="show_codes" :entryscreen="entryscreen"
                  ref="codes" @play="play" @reset="reset"
                  :clicked_code="clicked_code"
    ></codes-screen>
  </div>
</template>

<style lang="scss" scoped>
.app {
  width: 100%;
  max-width: 800px;
  margin: auto;

  .iframe & {
    max-width: none;
  }
}
</style>

<script>
import EntryScreen from "./screens/Entry";
import CodesScreen from "./screens/Codes";
import PlayScreen from "./screens/Play";
import {forceMailCode, play} from "../api";
import Alert from "./Alert";
import ClaimForm from "./ClaimForm";

export default {
  name: 'App',
  components: {ClaimForm, Alert, EntryScreen, CodesScreen, PlayScreen},
  data()
  {
    return {
      entryscreen: null,
      current_code: null,
      clicked_code: null,
      show_codes: true,
      next_codes: [],
      // shop_ids: [4, 5, 6, 7, 8, 9, 12]
      shop_ids: null,
      errors: null
    }
  },
  methods: {
    async play(input)
    {
      let
          /** @var {String} */
          code,
          next_codes = [];

      if (Array.isArray(input))
      {
        // we krijgen een array van codes, filter ze of ze gespeeld zijn
        next_codes = input.filter(code => typeof code === 'object' && !code.played_on);
        if (next_codes.length === 0)
        {
          // geen code dus ook niet spelen
          console.warn('play: next_codes does no have playable codes', input);
          return;
        }
        code = next_codes.shift().code;
      } else if (typeof input === 'object' && input.code && !input.played_on)
      {
        // we kregen een object met een code die nog niet gespeeld is
        code = input.code;
        next_codes = [];
      } else if (typeof input === 'string')
      {
        code = input;
      } else
      {
        return;
      }

      console.debug('play', {code, next_codes});

      this.clicked_code = null;
      this.current_code = null;
      this.next_codes = [];
      this.show_codes = false;
      this.$nextTick(async () =>
      {
        this.clicked_code = code;
        try {
          const data = await play(this.entryscreen.id, this.entryscreen.hash, code);
          // this.reloadCodes();
          this.next_codes = next_codes;
          this.current_code = data.code;
          this.shop_ids = data.shop_ids;
        } catch (e) {
          console.error(e);
          this.errors = ['Fout: ' + e];
        }
        this.clicked_code = null;
        this.show_codes = true;
      })
    },
    close() {
      this.current_code = null;
      this.$nextTick(() => {
        // https://github.com/davidjbradshaw/iframe-resizer/blob/master/docs/iframed_page/methods.md
        if ('parentIFrame' in window) {
          window.parentIFrame.scrollToOffset(0, 0);
        } else {
          try {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          } catch (e) {
            window.scrollTo(0, 0);
          }
        }
      })
    },
    reset() {
      localStorage.removeItem('hash');
      localStorage.removeItem('id');
      // because we are lazy :)
      location.reload();
    },
    reloadCodes() {
      if (this.$refs.codes) {
        this.$refs.codes.reload();
      }
    },
    async mail(code) {
      try {
        await forceMailCode(code);
        this.reloadCodes();
      } catch (e)
      {
        console.error(e);
      }
    }
  }
}
</script>