<template>
  <div>
    <div class="scene">
      <div class="inner" :class="[flipped&&'flipped']">
        <button class="face front" @click="flip" :style="{cursor:shop && 'pointer'}">
          <!--            <img src="https://hamershof.nl/modules/website/assets/HamershofLogo.svg" alt="Hamershof" loading="lazy">-->
          <span class="letter h1" v-html="letter"></span>
        </button>
        <!--
        <a v-if="shop" class="face back"
           :title="`${shop.title} op hamershof.nl`"
           :href="shop.hamershof_url" target="_blank">
          <img :src="logo_url" :alt="shop.title"
               loading="lazy" class="img-fluid">
        </a>
        -->
        <div v-if="shop" class="face back">
          <img :src="logo_url" :alt="shop.title" loading="lazy" class="img-fluid">
        </div>
      </div>
    </div>

    <alert v-model="errors"></alert>
  </div>
</template>

<style lang="scss" scoped>

.scene {
  perspective: 600px;
}

.inner {
  position: relative;
  padding-top: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;

  &.flipped {
    transform: rotateY(-180deg);
  }
}

.face {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  display: flex;
  background: #fff;
  border: 0;
  //border: 1px solid #ddd;
  //border-radius: 5px;

  img {
    max-width: calc(100% - 1em);
    max-height: calc(100% - 1em);
    margin: auto;
  }

  .letter {
    font-size: clamp(1rem, 17vw, 7rem);
    margin: auto;
  }

  @media (max-width: 400px) {
    //border: 0;
  }
}

.front {
  box-shadow: 0 0 0 transparent;
  background: rgba(#000, .6);
  color: #fff;
  text-shadow: 0 0 5px #000;
  transition: .5s;

  //background: rgba(#fff, .9);
  //color: #000;
  //
  //box-shadow: 0 0 1rem rgba(#000, .5);

  &:focus,
  &:hover {
    background: #000;
    color: #fff;
    transform: scale(.9);

    .flipped & {
      transform: scale(1);
    }
  }

  @media (max-width: 400px) {
    background: rgba(#000, .25);
  }
}

.back {
  transform: rotateY(180deg);
}
</style>

<script>
import {shop} from "../api";
import Alert from "./Alert";

import gravatarUrl from 'gravatar-url';

export default {
  components: {Alert},
  props: {
    value: {type: Boolean},
    shop_id: {type: Number, required: true},
    letter: String
  },
  data()
  {
    return {
      shop: null,
      flipped: false,
      errors: null
      // value: false
    }
  },
  computed: {
    logo_url()
    {
      if (this.shop.logo_url)
      {
        return this.shop.logo_url + '?resize=w[200]h[200]';
      }
      return gravatarUrl(`${this.shop.id}@hamershof.nl`, {
        size: 200,
        default: 'identicon'
      });
    }
  },
  async mounted() {
    try {
      this.shop = await shop(this.shop_id);
    } catch (e) {
      this.errors = [e];
    }
    this.flipped = this.value;
  },
  watch: {
    value(value) {
      this.flipped = value;
    }
  },
  methods: {
    flip() {
      if (this.shop) {
        this.flipped = true;
        setTimeout(() => this.$emit('input', true), 500);
      }
    }
  }
}
</script>