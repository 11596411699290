<template>

  <div>

    <div class="card mb-4">
      <!--    <div class="card-header d-flex justify-content-between align-items-center">-->
      <!--      Codes van {{ entryscreen.name }}-->
      <!--    </div>-->

      <div class="card-body">

        <claim-form :id="entryscreen.id" :hash="entryscreen.hash"
                    @claimed="reload" v-bubble:play
                    :max_codes_to_enter="entryscreen.extra_data.max_codes_to_enter"
        ></claim-form>

        <!--
        <div v-else-if="!codes || !codes.length" role="alert" class="alert alert-info">
          U heeft nog geen codes ingevoerd.
        </div>
        -->
      </div>
    </div>

    <transition name="fade">
      <center v-if="!loading">
        <button v-if="codes[1] && ! codes[1].played_on" class="btn btn-primary" @click="$emit('play',codes)"
                :disabled="refreshing"
        >
          Speel {{ codes.filter(code => !code.played_on).length }} codes achter elkaar door
        </button>
      </center>
    </transition>

    <div class="codes-wrap">
      <div v-if="codes && codes.length" class="card mb-4" v-for="(obj,date) in codes_by_date">
        <div class="card-header">{{ obj.label }}</div>
        <div class="list-group list-group-flush">
          <codes-list-item v-for="code in obj.codes" :key="code.code" :code="code" v-bubble:play
                           :clicked="clicked_code ? clicked_code === code.code : null"
          ></codes-list-item>
        </div>
      </div>
      <transition name="fade">
        <div v-if="refreshing || loading" role="alert" class="alert alert-info loading-alert"

             :class="[refreshing && 'refreshing', loading && 'loading']"
        >
          <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
          <template v-if="refreshing">
            Codes worden ververst&hellip;
          </template>
          <template v-else>
            Codes worden geladen&hellip;
          </template>
        </div>

      </transition>
    </div>
  </div>
</template>

<style scoped lang="scss">
.codes-wrap {
  position: relative;
  //min-height: 200px;
}

.loading-alert {

  &.refreshing {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
  }
}
</style>

<script>
import {IS_PRODUCTION, codes} from "../../api";
import CodesListItem from "../CodesListItem";
import ClaimForm from "../ClaimForm";

const isSameDate = (someDate, otherDate) => {
  return someDate.getDate() === otherDate.getDate() &&
      someDate.getMonth() === otherDate.getMonth() &&
      someDate.getFullYear() === otherDate.getFullYear()
}

const today = new Date();
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

export default {
  components: {ClaimForm, CodesListItem},
  props: {
    entryscreen: {type: Object, required: true},
    clicked_code: {type: String, required: false}
  },
  data()
  {
    return {
      loading: false,
      refreshing: false,
      codes: [],

      // t: null
    }
  },

  computed: {
    codes_by_date()
    {
      let groups = {};
      this.codes.forEach(code =>
      {
        const date = new Date((code.played_on || code.used_on).substr(0, 10));
        if (!groups.hasOwnProperty(date))
        {
          groups[date] = {
            date,
            label: isSameDate(today, date) && 'vandaag'
                || isSameDate(yesterday, date) && 'gisteren'
                || date.toLocaleDateString('nl'),
            codes: [code]
          };
        } else
        {
          groups[date].codes.push(code);
        }
      });
      return groups;
    }
  },

  mounted()
  {
    this.reload();
  },
  methods: {
    reload()
    {
      this.load(true);
    },
    async load(refreshing = false)
    {
      // console.log({refreshing});
      const prop = refreshing ? 'refreshing' : 'loading';
      this[prop] = true;
      try {
        this.codes = await codes(this.entryscreen.id, this.entryscreen.hash);
      } catch (e) {
        console.error(e);
      }


      // if (IS_PRODUCTION)
      // {
      //   if (this.t)
      //   {
      //     clearInterval(this.t);
      //   }
      //   this.t = setInterval(() => this.load(true), 30 * 1000);
      // }

      this[prop] = false;
    }
  }
}
</script>