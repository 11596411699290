<template>
  <div class="list-group-item">

    <dl class="row">
      <dt :class="dt_class">Code</dt>
      <dd :class="dd_class"><code>{{ code.code }}</code></dd>

      <dt :class="dt_class">Ingevuld</dt>
      <dd :class="dd_class">
        <date-display :date="code.used_on"></date-display>
      </dd>

      <template v-if="code.played_on">
        <dt :class="dt_class">Gespeeld</dt>
        <dd :class="dd_class">
          <date-display v-if="code.played_on" :date="code.played_on"></date-display>
          <span v-else class="text-muted">niet</span>
        </dd>
        <dt :class="dt_class">
          Gewonnen
        </dt>
        <dd :class="dd_class">
          <template v-if="code.won">Ja</template>
          <span class="text-muted" v-else>nee</span>
        </dd>
      </template>
    </dl>

    <price-display v-if="code.won&&code.price_id" :price_id="code.price_id"></price-display>

    <button v-if="code.used_on && !code.played_on" class="btn btn-primary" @click="$emit('play',code.code)"
            :disabled="clicked!==null"
    >
      <template v-if="clicked">
        Wordt geladen&hellip;
        <i class="fa fa-spin fa-spinner" aria-hidden="true"></i>
      </template>
      <template v-else>
        Speel
      </template>
    </button>
  </div>
</template>

<script>
import DateDisplay from "./DateDisplay";
import PriceDisplay from "./PriceDisplay";

export default {
  components: {PriceDisplay, DateDisplay},
  props: {
    code: {type: Object, required: true},
    clicked: {type: Boolean, required: false}
  },

  computed: {
    dt_class()
    {
      return 'col-sm-4 col-md-3';
    },
    dd_class()
    {
      return 'col-sm-8 col-md-9';
    }
  }
}
</script>