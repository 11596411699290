<template>
  <div class="modal fade" :class="[value && 'show in']" :tabindex="!value && -1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-html="title"></h5>
          <button type="button" class="close" :aria-label="`Sluit {{title}`" @click.prevent="$emit('input', false)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" v-html="body"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {tos} from '../api';

export default {
  props: {
    value: Boolean
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      this.body = await tos();
      this.title = 'Algemene voorwaarden';
    }
  },
  data() {
    return {
      open: true,
      title: 'Algemene voorwaarden worden geladen&hellip;',
      body: '<i class="fa fa-spin fa-spinner" aria-hidden="true"></i>'
    }
  }
}
</script>


<style lang="scss" scoped>
.modal {
  display: block;
  pointer-events: none;
}
</style>
