<template>
  <time :datetime="date_object.toISOString()" :title="date_object|dateFormat('dddd D MMMM YYYY HH:mm:ss')">
    {{ relative }}
  </time>
</template>

<script>

const units = [
  ['year', 31536000000],
  ['month', 2628000000],
  ['day', 86400000],
  ['hour', 3600000],
  ['minute', 60000],
  ['second', 1000],
]

const rtf = new Intl.RelativeTimeFormat('nl', {})
const relatime = elapsed =>
{
  for (const [unit, amount] of units)
  {
    if (Math.abs(elapsed) > amount || unit === 'second')
    {
      return rtf.format(Math.round(elapsed / amount), unit)
    }
  }
}
export default {
  props: {date: {required: true, default: () => new Date}},
  data()
  {
    return {
      now: Date.now()
    }
  },
  mounted()
  {

    setInterval(() => this.now = Date.now(), 1000);
  },
  computed: {
    relative()
    {
      return relatime(this.date_object - this.now);
    },
    date_object()
    {
      if (this.date instanceof Date)
      {
        return this.date;
      }
      return new Date(this.date);
    }
  }
}
</script>