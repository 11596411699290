import 'whatwg-fetch';

import queryString from 'query-string';
import cachify from 'await-cache';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';
const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

async function json_xhr(method, path, data = null, headers = null) {
    method = method.toUpperCase();

    if (typeof headers !== 'object' || headers === null) {
        headers = {};
    }

    console.debug(typeof headers, {method, path, data, headers});

    if (method === 'GET') {
        const str = queryString.stringify(data);
        if (str.length) {
            if (path.indexOf('?') > -1) {
                path = path + '&' + str;
            } else {
                path = path + '?' + str;
            }
        }
    }

    const url = new URL(path, window.BACKEND_URL);

    const obj = {
        method: method,
        headers: headers,
        body: null
    };
    if (method !== 'GET' && method !== 'HEAD') {
        obj.headers['Content-Type'] = 'application/json';
        obj.body = JSON.stringify(data);

        if (method !== 'POST') {
            obj.method = 'POST';
            obj.headers['X-HTTP-Method-Override'] = method;
        }
    }

    if (obj.method === 'GET') {
        delete obj.method;
    }

    if (!obj.headers) {
        delete obj.headers;
    }
    if (!obj.body) {
        delete obj.body;
    }

    console.debug('fetch', {url: url.toString(), obj});

    const response = await fetch(url.toString(), obj);

    try {
        data = await response.json();
    } catch (e) {
        throw e;
    }
    if (typeof data === 'object' && data.status_code) {
        throw data.reason_phrase;
    }
    return data;
}

async function post(path, data = null, headers = null) {
    return json_xhr('POST', path, data, headers);
}

async function put(path, data = null, headers = null) {
    return json_xhr('PUT', path, data, headers);
}

async function get(path, data = null, headers = null) {
    return json_xhr('GET', path, data, headers);
}

async function signup(data) {
    return post('signup', data);
}

async function contestant(id, hash) {
    return get('contestants/' + id, null, {Authorization: `Bearer ${hash}`});
}

async function codes(id, hash) {
    return get('contestants/' + id + '/codes', null, {Authorization: `Bearer ${hash}`});
}

async function claim(id, hash, codes) {
    return post('contestants/' + id + '/codes', {codes}, {Authorization: `Bearer ${hash}`});
}

async function play(id, hash, code) {
    return post('contestants/' + id + '/codes/' + code, null, {Authorization: `Bearer ${hash}`});
}

async function forceMailCode(code) {
    return post('/force-mail/' + code);
}

const price = cachify(async id => get('prices/' + id));
const shop = cachify(async id => get('shops/' + id));
const tos = cachify(async () => get('/tos'), 60 * 30 * 1000)

export {
    IS_PRODUCTION,
    IS_DEVELOPMENT,
    forceMailCode,
    play,
    price,
    shop,
    contestant,
    signup,
    claim,
    codes,
    tos
}