<template>
  <form @submit.prevent="claim">

    <label class="h1 d-block mb-3 text-center" :for="$id('codes')" style="cursor: pointer">

      <template v-if="max_codes_to_enter < 0">
        Vul uw code(s) in
      </template>
      <template v-else>
        Vul uw code in
      </template>
    </label>

    <div class="form-group">

      <textarea v-if="max_codes_to_enter < 0"
                class="form-control form-control-lg"
                :id="$id('codes')" v-model.trim="codes"
                :readonly="posting" placeholder="Vul hier uw code(s) in."
      ></textarea>

      <input v-else type="text" class="form-control form-control-lg text-center" :id="$id('codes')" v-model.trim="codes"
             :disabled="max_codes_to_enter === 0"
             :readonly="posting" placeholder="X8YAB6DD"/>

      <p class="form-text text-center small">
        <template v-if="max_codes_to_enter < 0">
<!--          Vul uw codes in met een komma of een spatie,-->
<!--          bijvoorbeeld: 'AB12XY,YU89ZZ' of 'AB12XY YU89ZZ'.-->
        </template>
        <template v-else-if="max_codes_to_enter === 0">
          U heeft deze week al een code ingevuld. Volgende week kunt u meer codes invullen.
        </template>
        <template v-else-if="max_codes_to_enter === 1">
          U kan deze week één code invullen. Volgende week kunt u meer codes invullen.
        </template>
      </p>

    </div>

    <alert type="danger" v-model="errors"></alert>

    <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="posting || max_codes_to_enter === 0"
            :title="posting ? 'De code\'s worden geregistreerd':'Verstuur deze code(s)'">
      <span v-if="posting">
        <span v-if="max_codes_to_enter < 0">
          De code's worden geregistreerd&hellip;
        </span>
        <span v-else>
          De code wordt geregistreerd&hellip;
        </span>
        <i class="fa fa-spin fa-spinner" aria-hidden="true"></i>
      </span>
      <span v-else>
        <span v-if="max_codes_to_enter < 0">
          Registreer deze code(s)
        </span>
        <span v-else>Registreer deze code</span>
        <i class="fa fa-send" aria-hidden="true"></i>
      </span>
    </button>

  </form>
</template>

<style scoped>
textarea::placeholder {
  text-align: center;
}
</style>

<script>
import {claim} from "../api";
import Alert from "./Alert";

export default {
  components: {Alert},
  props: {
    id: {type: Number, required: true},
    hash: {type: String, required: true},
    max_codes_to_enter: {type: Number, default: () => -1}
  },
  data()
  {
    return {
      codes: '',
      errors: null,
      posting: false
    }
  },
  methods: {
    async claim()
    {
      if (this.max_codes_to_enter === 1 && /\s|,/.test(this.codes))
      {
        this.errors = ['U kan maar één code registreren.'];
        return;
      }

      this.posting = true;
      try
      {
        const data = await claim(this.id, this.hash, this.codes);
        this.errors = Array.isArray(data.errors) ? data.errors : [];

        if (Array.isArray(data.claimed_codes) && this.errors.length === 0)
        {
          this.$emit('play', data.claimed_codes);
        }

      } catch (e)
      {
        this.errors = ['Fout tijdens registreren van code: ' + e];
      }

      this.posting = false;
    }
  }
}
</script>