<template>
  <form @submit.prevent="submit" novalidate="novalidate">
    <div class="card">
      <!--      <div class="card-header">Vul uw gegevens in</div>-->
      <div class="card-body">

        <center>
          <h1>Vul uw gegevens in</h1>
        </center>

        <div class="form-group">
          <label :for="$id('name')">Naam:</label>
          <input type="text" required :id="$id('name')" v-model="name" class="form-control" placeholder="Uw naam"
                 :class="[errors.name && 'is-invalid']" :readonly="loading"
                 @input="delete errors.name"
          >
          <div class="invalid-feedback" v-html="errors.name"></div>
        </div>

        <div class="form-group">
          <label :for="$id('email')">E-mail adres:</label>
          <input type="text" required :id="$id('email')" v-model="email" class="form-control"
                 :readonly="loading"
                 :class="[errors.email && 'is-invalid']"
                 @input="delete errors.email"

                 placeholder="uwnaam@domein.nl">
          <div class="invalid-feedback" v-html="errors.email"></div>
        </div>

        <div class="form-group">
          <label :for="$id('zipcode')">Postcode:</label>
          <input type="text" required :id="$id('zipcode')" v-model="zipcode" class="form-control" placeholder="3800ZZ"
                 :class="[errors.zipcode && 'is-invalid']" :readonly="loading"
                 @input="delete errors.zipcode" pattern="[1-9]\d{3}[A-Za-z]{2}">
          <div class="invalid-feedback" v-html="errors.zipcode"></div>
        </div>


        <div class="form-group">

          <!--
          <div class="form-check">
            <input class="form-check-input" :id="$id('accepted_tos')" type="checkbox" v-model="accepted_tos" required
                   :class="[errors.accepted_tos && 'is-invalid']" :readonly="loading"
                   @input="delete errors.accepted_tos"
            >
            <label class="form-check-label" :for="$id('accepted_tos')"> Ik ga akkoord met de algemene
              voorwaarden </label>

          </div>
          -->
          <div class="custom-control custom-checkbox">
            <input class="custom-control-input" :id="$id('accepted_tos')" type="checkbox" v-model="accepted_tos"
                   required
                   :class="[errors.accepted_tos && 'is-invalid']" :readonly="loading"
                   @input="delete errors.accepted_tos"
            >
            <label class="custom-control-label" :for="$id('accepted_tos')">
              Ik ga akkoord met <a href="#" @click.prevent="tos_modal_shown = true">
              de algemene voorwaarden</a>.
            </label>
          </div>
          <div class="invalid-feedback" v-html="errors.accepted_tos"></div>
        </div>

        <!--
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input :id="$id('newsletters')" type="checkbox" :readonly="loading"
                   v-model="newsletters" class="custom-control-input">
            <label class="custom-control-label" :for="$id('newsletters')">
              Ik wil graag nieuwsbrieven ontvangen
            </label>
          </div>
        </div>
        -->

        <alert v-if="Array.isArray(errors)" v-model="errors"></alert>

        <div class="text-center">
          <button type="submit" class="btn btn-lg btn-primary btn-block" :disabled="loading">
            <template v-if="loading">
              <i class="fa fa-fw fa-spinner fa-spin" aria-hidden="true"></i> Aan het verwerken&hellip;
            </template>
            <template v-else>
              Doorgaan
              <i class="fa fa-fw fa-sign-in" aria-hidden="true"></i>
            </template>
          </button>
        </div>
      </div>
    </div>

    <TosModal v-model="tos_modal_shown"></TosModal>
  </form>
</template>

<style lang="scss" scoped>
label a[href] {
  color: inherit;
  text-decoration: underline;
}
</style>

<script>
import {contestant, signup} from '../../api';
import Alert from "../Alert";
import TosModal from "../TosModal";

const default_data = {
  id: null,
  hash: null,

  name: null,
  street: null,
  city: null,
  zipcode: null,
  phone: null,
  email: null,

  accepted_tos: false,
  newsletters: false,

  loading: false,

  errors: {},
  alert_errors: null,

  extra_data: null
};

export default {
  name: 'EntryScreen',
  components: {TosModal, Alert},
  props: {
    value: Object
  },
  data() {
    return {
      ...default_data,

      tos_modal_shown: null
    };
  },
  mounted() {
    Object.entries(default_data, ([key, value]) => this[key] = value);
    this.load();
    this.update();
  },
  methods: {
    reset() {
      this.save();
      this.update();
    },
    save() {
      localStorage.setItem('hash', this.hash);
      localStorage.setItem('id', this.id);
    },
    async load() {
      const hash = localStorage.getItem('hash');
      const id = localStorage.getItem('id');
      console.log('contestant', {id, hash});
      if (hash && id) {
        this.loading = true;
        try {
          const data = await contestant(id, hash);
          if (data.id) {
            Object.entries(data).forEach(entry => this[entry[0]] = entry[1]);
            this.hash = hash;
            this.update();
          }
        } catch (e) {
          console.error(e);
          this.errors = [e];
        }
        this.loading = false;
      }
    },
    update() {
      this.$emit('input', {
        id: this.id,
        hash: this.hash,

        name: this.name,
        email: this.email,
        zipcode: this.zipcode,

        accepted_tos: this.accepted_tos,
        newsletters: this.newsletters,

        // extra values
        street: null,
        city: null,
        phone: null,

        extra_data: this.extra_data

      });
    },
    async submit() {
      this.loading = true
      try {
        const data = await signup({
          name: this.name,
          email: this.email,
          zipcode: this.zipcode,

          accepted_tos: this.accepted_tos,
          newsletters: this.newsletters
        });

        this.errors = data.errors
        if (data.success) {
          this.id = data.id;
          this.hash = data.hash;
          this.save();
          return this.load();
        }
      } catch (e) {
        this.errors = [e];
      }
      this.loading = false
    }
  }
}
</script>