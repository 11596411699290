import 'regenerator-runtime/runtime';

import Vue from 'vue';
import App from './components/App';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

window.iFrameResizer = {
    targetOrigin: '*'
}

if (window.top === window.self)
{
    document.querySelector('html').className += 'no-iframe';
} else
{
    document.querySelector('html').className += 'iframe';
}

Vue.config.productionTip = false;

Vue.use((Vue) =>
{
    // Assign a unique id to each component
    let uuid = 0;
    Vue.mixin({
        beforeCreate: function ()
        {
            this.uuid = uuid.toString();
            uuid += 1;
        },
    });

    // Generate a component-scoped id
    Vue.prototype.$id = function (id)
    {
        return "vue-component-" + this.uuid + "-" + id;
    };
});
Vue.directive('bubble', {
    bind(el, {value, arg: prefix = '', modifiers}, {context, componentInstance})
    {
        const events = value && value.trim() ? value.split(',') : Object.keys(modifiers);
        if (!events.length && prefix)
        {
            events.push(prefix);
            prefix = '';
        } else if (prefix)
        {
            prefix = prefix === '_' ? '' : prefix += '-';
        }

        if (!componentInstance || !context || !events.length)
        {
            return;
        }

        events.forEach((v) =>
        {
            v = v.trim();
            const eventName = `${prefix}${v}`;
            const bubble = context.$emit.bind(context, eventName);
            // console.log(v, bubble);
            componentInstance.$on(v, bubble);
        });
    }
});

Vue.use(VueFilterDateFormat, {
    dayOfWeekNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
    dayOfWeekNamesShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
    monthNames: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec']
});

new Vue({
    render: h => h(App)
}).$mount('#app');