<template>
  <div v-if="value && value.length" role="alert" class="alert" :class="[`alert-${type}`]">
    <button @click="$emit('input',null)" class="close" aria-label="Sluiten">
      <i class="fa fa-close" aria-hidden="true"></i>
    </button>
    <template v-if="value.length === 1">
      <template v-for="error in value">
        {{ error }}
      </template>
    </template>
    <template v-else>
      <ul class="mb-0">
        <li v-for="error in value">{{ error }}</li>
      </ul>
    </template>
  </div>

</template>

<script>
export default {
  props: {
    type: {
      type: String, default()
      {
        return 'danger';
      }
    },
    value: {
      type: Array, required: false
    }
  }
}
</script>