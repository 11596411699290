<template>
  <div class="card">
    <div class="card-body">

      <center>
        <h1>Hamershof logospel</h1>
        <p>Als u drie winkel logo's op een rij heeft, dan wint u een prijs. Draai de kaarten &eacute;&eacute;n voor
          &eacute;&eacute;n om.</p>
      </center>
    </div>

    <div class="popup-holder">
      <div class="tictactoe">
        <template v-for="y in [0,1,2]">
          <div class="tictactoe-cell" v-for="x in [0,1,2]" :key="`${x},${y}`">
            <tic-tac-toe-shop :shop_id="cells[x][y]" v-model="flipped[x][y]" :letter="letters[y][x]"
                              :class="get_cell_classes(x,y)"
            ></tic-tac-toe-shop>
            <!--            {{ x }},{{ y }}-->
          </div>
        </template>
      </div>


      <transition name="popup">
        <div class="popup-wrap" v-if="done" :style="`transition-delay: ${won ? 4 : 2}s`">

          <div class="text-center popup">
            <div v-if="won">
              <div class="alert alert-success" role="alert">
                <!--          <button type="button" class="close" aria-label="Sluit" @click="close">-->
                <!--            <span aria-hidden="true">&times;</span>-->
                <!--          </button>-->
                Gefeliciteerd! U heeft een prijs gewonnen met code <code>{{ code.code }}</code>!
                U krijgt hiervan een mail ter bevestiging.
              </div>
              <price-display :price_id="code.price_id" @loaded="scroll"></price-display>
            </div>
            <div v-if="lost">
              <div class="alert alert-warning" role="alert">
                <!--          <button type="button" class="close" aria-label="Sluit" @click="close">-->
                <!--            <span aria-hidden="true">&times;</span>-->
                <!--          </button>-->
                Helaas. Met code <code>{{ code.code }}</code> heeft u niets gewonnen.
              </div>
            </div>

            <div class="d-flex flex-wrap" style="gap:.5em">
              <button class="btn btn-lg flex-grow-1" @click="close"
                      :class="[next_codes.length ? 'btn-primary':'btn-secondary']"
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                <!--          Terug naar de lijst van mijn codes-->
                Terug
              </button>

              <button v-if="next_codes.length" class="btn btn-primary btn-lg flex-grow-1"
                      @click="$emit('play', next_codes)">
                <template class="card-header text-center" v-if="next_codes.length > 1">
                  Doorspelen met nog {{ next_codes.length }} codes
                </template>
                <template class="card-header text-center" v-else>
                  Doorspelen met de laatste code
                </template>
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<style lang="scss" scoped>

@import "../../variables";

/*
popup structuur:

.popup-holder (container om het spel met position:relative)
.popup-wrap (div over het spel met position:absolute, 100% hoogte&breedte, display:flex voor centreren)
.popup (de eigenlijke popup, met automatische breedte en hoogte)

*/
.popup {
  transition-delay: 2s;

  &-holder {
    position: relative;
  }

  &-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  max-width: calc(100% - 4rem);
  @media (max-width: 500px) {
    max-width: 100%;
  }
  padding: 1rem;
  //background: rgba(#fff, .95);
  background: #fff;
  box-shadow: 0 0 1rem rgba(#000, .25);

}


.popup-enter-active,
.popup-leave-active {
  will-change: opacity;
  transition: opacity .5s;
}

.popup-enter,
.popup-leave-to {
  opacity: 0;
}

.tictactoe {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns:1fr 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  padding: 1rem;
  margin: 0 auto;
  background: linear-gradient(rgba(#fff, 0.4), rgba(#fff, 0.9)), url('../../bg.jpg') center center;
  background-size: cover;

  transition: .5s;

  &-letter {
    font-size: clamp(1rem, 10vw, 8rem);
    margin: auto;
  }

  @media (max-width: 400px) {
    column-gap: 2px;
    row-gap: 2px;
    padding: 2px;
  }
}

.pulse {
  animation: pulse 1s ease-in-out 4;
}

.pulse-1 {
  animation-delay: .33s;
}

.pulse-2 {
  animation-delay: .66s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: .7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>

<script>
import TicTacToeShop from "../TicTacToeShop";

import won from '../../won.mp3';
import lost from '../../lost.mp3';
import PriceDisplay from "../PriceDisplay";
import {IS_DEVELOPMENT} from "../../api";

const won_sound = new Audio(won);
const lost_sound = new Audio(lost);

const winning_rows = [
  // horizontal
  [[0, 0], [1, 0], [2, 0]],
  [[0, 1], [1, 1], [2, 1]],
  [[0, 2], [1, 2], [2, 2]],

  // vertical
  [[0, 0], [0, 1], [0, 2]],
  [[1, 0], [1, 1], [1, 2]],
  [[2, 0], [2, 1], [2, 2]],

  // diagonal
  [[0, 0], [1, 1], [2, 2]],
  [[2, 0], [1, 1], [0, 2]]
];


export default {
  components: {PriceDisplay, TicTacToeShop},
  props: {
    // won: {
    //   type: Boolean,
    //   required: true
    // },
    shop_ids: {
      type: Array,
      required: true,
      validator: shop_ids => {
        if (!Array.isArray(shop_ids)) {
          console.warn('shop_ids is not an array', {shop_ids});
          return false;
        }

        if (!shop_ids.length === 7) {
          console.warn(`shop_ids contains ${shop_ids.length} elements instead of 7`, {shop_ids})
          return false;
        }

        if (shop_ids.some(shop_id => isNaN(shop_id))) {
          console.warn(`shop_ids has one or more non-number elements`, {shop_ids});
          return false;
        }
        return true;
      }
    },
    code: {
      type: Object,
      required: true,
      validator: code => typeof code === 'object'
          && code.hasOwnProperty('code') &&
          code.hasOwnProperty('won')
    },
    next_codes: {type: Array, default: () => []}
  },
  methods: {
    scroll() {
      /*
      try {
        this.$refs.done.scrollIntoView({behavior: 'smooth'});
      } catch (e) {
        console.error(e);
      }
      */
    },
    // controleert of een rij kan winnen.
    is_possible_winning_row(row) {
      let price_id;
      let possible = true;
      row.forEach(([x, y]) => {
        if (this.flipped[x][y]) {
          if (!price_id) {
            price_id = this.cells[x][y];
          } else if (possible) {
            possible = price_id === this.cells[x][y];
          }
        }
      });

      return possible;
    },
    is_flipped_row(row) {
      //const flipped = row.every(([x, y]) => this.flipped[x][y])
      // console.debug('is_flipped_row', {row, flipped});
      // return flipped;
      return row.every(([x, y]) => this.flipped[x][y]);
    },

    get_cell_classes(x, y) {
      let classes = [];
      if (this.winning_row && this.won) {
        // console.debug('-----');
        // console.debug(x + '-' + y);
        // let table = [];
        this.winning_row.forEach(([x2, y2], index) => {
          // const win = x === x2 && y === y2;
          // table.push({x2, y2, win});

          // if (win) {
          if (x === x2 && y === y2) {
            classes.push('pulse');
            if (index) {
              classes.push('pulse-' + index);
            }
          }
        });
        // console.table(table);
        // console.debug(classes);
      }
      return classes;
    },

    close() {
      this.$emit('close');
    }
  },
  computed: {
    won() {
      // gewonnen zodra de rij die wint helemaal omgedraaid is.
      return this.winning_row && this.is_flipped_row(this.winning_row)
    },
    lost() {
      // verloren zodra er geen mogelijke winrij is.
      return !winning_rows.some(row => this.is_possible_winning_row(row));
    },
    done() {
      // klaar zodra men heeft gewonnen of verloren.
      return this.won || this.lost;
    },
    letters() {
      return [
        ['H', 'A', 'M'],
        ['E', 'R', 'S'],
        ['H', 'O', 'F']
      ];
    }
  },
  watch: {
    done(done) {
      if (done) {
        console.log('All done. updating flipped.');
        let flipped = [[], [], []];
        for (let x = 0; x < 3; x++) {
          for (let y = 0; y < 3; y++) {
            flipped[x][y] = true;
          }
        }
        this.flipped = flipped;

        this.$nextTick(this.scroll);
      }
    },
    won(won) {
      if (won) {
        // if (!IS_DEVELOPMENT)
        // {
        won_sound.play();
        // }
        this.$emit('won', this.code.code);
      }
    },
    lost(lost) {
      if (lost) {
        // if (!IS_DEVELOPMENT)
        // {
        lost_sound.play();
        // }
        this.$emit('lost', this.code.code);
      }
    }
  },
  mounted() {
    // won_sound.play();
    console.log(this.$refs);
  },
  data() {
    let winning_row;
    let cells = [[], [], []];

    const shop_ids = this.shop_ids;

    if (this.code.won) {
      // pick a random winning row.
      winning_row = winning_rows[Math.floor(Math.random() * winning_rows.length)];

      // for the pulse animation to sometimes start at the bottom.
      if (Math.random(1, 2) === 1) {
        winning_row.reverse();
      }
      // pick the winning shop_id
      const shop_id = shop_ids.shift();
      winning_row.forEach(([x, y]) => cells[x][y] = shop_id)
    }

    shop_ids.sort(() => .5 - Math.random());
    let i = 0;
    while (shop_ids.length < 9) {
      shop_ids.push(shop_ids[i]);
      i++;
    }

    let flipped = [[], [], []];
    for (let x = 0; x < 3; x++) {
      for (let y = 0; y < 3; y++) {
        flipped[x][y] = false;
        if (!cells[x][y]) {
          cells[x][y] = shop_ids.shift();
        }
      }
    }

    return {
      cells: cells,
      winning_row: winning_row,
      flipped: flipped
    }
  },
}
</script>