<template>
  <div>
    <div v-if="loading">
      <i class="fa fa-spin fa-spinner" aria-hidden="true"></i>
      Prijs wordt geladen&hellip;
    </div>
    <div class="text-center" v-else>

      <alert v-model="errors"></alert>

      <h3>{{ name }}</h3>
      <img v-if="image_url" :src="image_url+'?resize=w[540]h[540]'" loading="lazy" width="540" height="540"
           style="width: auto;height:auto" :alt="name"
           class="img-fluid rounded">

      <p v-if="contact && shop_name">Ophalen: {{ contact }} - {{ shop_name }}</p>
      <p v-else-if="contact">Ophalen: {{ contact }}</p>
      <p v-else-if="shop_name">Ophalen: {{ shop_name }}</p>

      <shop-display v-if="shop"
                    :title="shop.title"
                    :description="shop.description"
                    :logo_url="shop.logo_url"
                    :url="shop.url"
                    :hamershof_url="shop.hamershof_url"
                    :address="shop.address"
                    :phone_e164="shop.phone_e164"
                    :phone_formatted="shop.phone_formatted"
                    @loaded="loaded"
      ></shop-display>
    </div>
  </div>
</template>

<script>
import {price} from '../api';
import ShopDisplay from "./ShopDisplay";
import Alert from "./Alert";

export default {
  components: {
    Alert,
    ShopDisplay
  },
  props: {
    price_id: {
      type: Number, required: true
    }
  },
  data()
  {
    return {
      loading: false,
      contact: null,
      hoofdprijs: null,
      image: null,
      image_url: null,
      name: null,
      shop_name: null,

      shop: null,
      errors: null,
    }
  },
  mounted()
  {
    this.load();
  },
  methods: {
    loaded()
    {
      this.$emit('loaded');
    },
    async load()
    {
      this.loading = true;
      try
      {
        const _price = await price(this.price_id);
        this.contact = _price.contact;
        this.hoofdprijs = _price.hoofdprijs;
        this.image = _price.image;
        this.image_url = _price.image_url;
        this.name = _price.name;
        this.shop_name = _price.shop_name;

        this.shop = _price.shop;

      } catch (e)
      {
        this.errors = [e];
      }
      this.loading = false;
      if (!this.shop)
      {
        this.loaded();
      }
    }
  }
}
</script>